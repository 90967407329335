var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { createSlice } from '@reduxjs/toolkit';
import { IStatus } from '../../../@types/status';
import { editReceiptPayment, getAllReceiptPayment, getOneReceiptPayment, getReceiptPaymentByTitle } from './actions';
var initialState = {
    receiptPayments: { docs: [], meta: {} },
    receiptPayment: {},
    status: IStatus.IDLE,
};
var slice = createSlice({
    name: 'recieptPayments',
    initialState: initialState,
    reducers: {
        createReceiptPayment: function (state, _a) {
            var payload = _a.payload;
            state.status = IStatus.SUCCEEDED;
            state.receiptPayments.docs = __spreadArray(__spreadArray([], state.receiptPayments.docs, true), [payload], false);
        },
    },
    extraReducers: function (builder) {
        builder
            .addCase(getAllReceiptPayment.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(getAllReceiptPayment.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.status = IStatus.SUCCEEDED;
            state.receiptPayments = payload;
        })
            .addCase(getAllReceiptPayment.rejected, function (state) {
            state.status = IStatus.FAILED;
        })
            .addCase(getOneReceiptPayment.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(getOneReceiptPayment.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.status = IStatus.SUCCEEDED;
            state.receiptPayment = payload;
        })
            .addCase(getOneReceiptPayment.rejected, function (state) {
            state.status = IStatus.FAILED;
        })
            .addCase(editReceiptPayment.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(editReceiptPayment.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.status = IStatus.SUCCEEDED;
            var data = payload.data;
            state.receiptPayments.docs = state.receiptPayments.docs.map(function (elt) {
                return elt._id === data._id ? data : elt;
            });
            state.receiptPayment = data;
        })
            .addCase(editReceiptPayment.rejected, function (state) {
            state.status = IStatus.FAILED;
        })
            .addCase(getReceiptPaymentByTitle.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(getReceiptPaymentByTitle.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.status = IStatus.SUCCEEDED;
            state.receiptPayment = payload;
        })
            .addCase(getReceiptPaymentByTitle.rejected, function (state) {
            state.status = IStatus.FAILED;
        });
    },
});
export var createReceiptPayment = slice.actions.createReceiptPayment;
export default slice.reducer;
